import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Image from "react-bootstrap/Image";
import * as styles from "./ProductThumbnail.module.css";

const defaultImageUrl = "../../images/placeholder.png";

interface IProps {
  title: string;
  imageUrl: string;
  productId: string;
}

const ProductThumbnail: React.FC<IProps> = ({ title, imageUrl, productId }) => {
  const [imageError, setImageError] = useState<boolean>(false);
  return (
    <>
      <Row>
        <Col>
          <div className={styles.imageContainer}>
            <Image
              src={
                imageError
                  ? defaultImageUrl
                  : imageUrl
                  ? imageUrl
                  : defaultImageUrl
              }
              className={styles.image}
              fluid
              onError={() => {
                setImageError(true);
              }}
            />
          </div>
        </Col>
      </Row>
      <Row className="mt-2">
        <Col xs={12}>
          <h3 className={styles.productName}>{title}</h3>
        </Col>
      </Row>
    </>
  );
};

export default ProductThumbnail;
