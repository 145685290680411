import React from "react";
import * as styles from "./Portfolio.module.css";
import { findThumbnail } from "../../utils/images";
import ProductThumbnail from "../../components/ProductThumbnail/ProductThumbnail";
import { Link } from "gatsby";

interface IProps {
  products: any;
}

const Portfolio: React.FC<IProps> = ({ products }) => {
  return (
    <div className="row">
      {products.map((product: any) => (
        <div
          key={product.node._id}
          className={`col-12 col-md-4 ${styles.clickableCol}`}
        >
          <Link to={`/${product.node.slug}`}>
            <ProductThumbnail
              title={product.node.name}
              imageUrl={findThumbnail(product.node)}
              productId={product.node._id}
            />
          </Link>
        </div>
      ))}
    </div>
  );
};

export default Portfolio;
