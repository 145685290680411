import React from "react";
import Layout from "../components/Layout";
import Portfolio from "../containers/Portfolio/Portfolio";
import { graphql } from "gatsby";
import { Helmet } from "react-helmet";
import "../styles/360-catalog.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import ButtonBanner from "../components/ButtonBanner";

const ThreeDee = (props) => {
  const products = props.data.allProducts3D.edges;
  return (
    <Layout>
      <Helmet>
        <title>HelloAR: AR | VR | MR</title>
        <meta
          name="description"
          content="Visit our gallery to explore the AR VR MR solutions for domains like furniture, auto, rugs, carpets, sanitaryware, arts & art galleries"
        />
        <meta name="keywords" content="ar vr mr" />
        <meta httpEquiv="X-UA-Compatible" content="ie=edge" />
        <meta httpEquiv="Content-Type" content="text/html;charset=UTF-8" />
      </Helmet>
      <div className="container-fluid catalog">
        <div className="spacer d-sm-none"></div>
        <div className="spacer"></div>
        <div className="spacer-sm"></div>
        <div className="container">
          <h1 className="h1 text-dark">3D examples with animations</h1>
          <h2 className="mt-4 bt-1 text-dark mb-4">
            With HelloAR platform, you can create interactive 3D and augmented
            reality experience of your products.
          </h2>
          <h2 className="bt-1 text-dark mb-4">
            Tap any of the 3D models below to experience the product in 3D. Tap
            on "View in your space" on a mobile device to view the object in
            your space using AR.
          </h2>
          <Portfolio products={products} />
        </div>
        <div className="spacer"></div>
        <div className="section-4">
          <div className="container">
            <div className="w-100 d-inline-flex flex-column align-items-center">
              <ButtonBanner
                buttonTitle="REQUEST A DEMO"
                buttonUrl="/contact-us"
              >
                <h1 className="h1 text-center text-light px-3">
                  One stop solution.
                  <br />
                  Zero upfront cost. Pay as you go.
                </h1>
              </ButtonBanner>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default ThreeDee;

export const query = graphql`
  query Product3DQuery {
    allProducts3D {
      edges {
        node {
          _id
          name
          slug
          description
          images
          thumbnail
          three_dee_model {
            glb
          }
        }
      }
    }
  }
`;
